export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '3756468626175941',
  agentpwd : '3756@5941$',
  apikey : '4936e006817e43a7164c65a896b1ef2f',
  url : 'https://oshop.afrikpay.com',
  id : 'oshop.afrikpay.com',
  accepturl : 'https://oshop.afrikpay.com/payment/web/success',
  cancelurl : 'https://oshop.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afrikpay.com/print/",
  storage : 'home/oss'
};